<template>
  <div class="iq-top-navbar">
    <nav class="navbar navbar-expand-lg navbar-margin navbar-light py-2">
      <div class="navbar-left">
        <img src="../../../../assets/images/ark logo 2-01 1.png" class="ark-logo" />
      </div>
        <ul class="navbar-nav ml-auto navbar-list">
          <li class="nav-item nav-item-top mr-1 head-lang nav-head" v-nav-toggle>
            <a class="search-toggle iq-waves-effect language-title" href="#"><img :src="selectedLang.image" alt="img-flaf" class="img-fluid mr-1" style="height: 16px; width: 16px;" /><i class="ri-arrow-down-s-line"></i></a>
            <div class="iq-sub-dropdown">
              <a class="iq-sub-card" href="javascript:void(0)" v-for="(lang, i) in langsOptions" :key="`Lang${i}`" @click="langChange(lang)">
                <img :src="lang.image" alt="img-flaf" class="img-fluid mr-2" />{{ lang.title }}
              </a>
            </div>
          </li>
          <li class="nav-item nav-item-top mr-2 nav-head">
            <div class="pt-3">
              <b-avatar variant="info" square text="CI"></b-avatar>
            </div>
          </li>
          <li  class="nav-item nav-item-top mr-5 nav-head"><div class="accountno"><p>ARK20220121</p></div></li>
          <li  class="nav-item nav-item-top mr-5 nav-head"><div @click="logout" class="accountno logout"><p>Logout</p></div></li>
          <li class="nav-item nav-item-top" v-nav-toggle>
            <a href="#" class="iq-waves-effect d-flex align-items-center bg-light rounded">
                <div class="p-1">
                  <i class="ri-menu-3-line"></i>
                </div>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0">
                  <div class="bg-primary p-3">
                    <div class="d-inline">
                      <b-avatar class="mr-3" variant="info" square text="CI"></b-avatar>
                      <span>ARK20220121</span>
                      <span @click="logout"><strong>Logout</strong></span>
                    </div>
                  </div>
                  <router-link :to="{ name: 'dashboard.home-1'}" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M6.25 13.5833H11.75M13.5833 17.25H4.41667C3.44421 17.25 2.51158 16.8637 1.82394 16.1761C1.13631 15.4884 0.75 14.5558 0.75 13.5833V7.81567C0.749987 7.1852 0.91254 6.56538 1.22196 6.01607C1.53138 5.46675 1.97722 5.0065 2.51642 4.67976L7.09975 1.90226C7.67277 1.55502 8.32998 1.37143 9 1.37143C9.67002 1.37143 10.3272 1.55502 10.9003 1.90226L15.4836 4.67976C16.0226 5.00642 16.4684 5.46652 16.7778 6.01567C17.0872 6.56481 17.2499 7.18444 17.25 7.81476V13.5833C17.25 14.5558 16.8637 15.4884 16.1761 16.1761C15.4884 16.8637 14.5558 17.25 13.5833 17.25Z" stroke="#0071BB" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Dashboard</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link :to="{ name: 'dashboard.orders'}" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 14" fill="none">
                        <path d="M2 0.25C1.1675 0.25 0.5 0.9175 0.5 1.75V4.75C0.5 5.5825 1.1675 6.25 2 6.25H5C5.8325 6.25 6.5 5.5825 6.5 4.75V1.75C6.5 0.9175 5.8325 0.25 5 0.25M5.15 1.375L5.945 2.1625L2.9525 5.125L1.055 3.2125L1.8575 2.425L2.96 3.5425M2 7.75C1.1675 7.75 0.5 8.4175 0.5 9.25V12.25C0.5 13.0825 1.1675 13.75 2 13.75H5C5.8325 13.75 6.5 13.0825 6.5 12.25V9.25C6.5 8.4175 5.8325 7.75 5 7.75M2 9.25H5V12.25H2M8 1.75H15.5V3.25H8M8 12.25V10.75H15.5V12.25M8 6.25H15.5V7.75H8V6.25Z" fill="#0071BB"/>
                        </svg>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Orders</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link :to="{name: 'dashboard.portfolio'}" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" viewBox="0 0 12 8" fill="none">
                        <path d="M0 5.99999H4.26667C4.48733 5.99999 4.67 6.18532 4.76667 6.38399C4.904 6.66599 5.22933 6.99999 6 6.99999C6.77067 6.99999 7.096 6.66666 7.23333 6.38399C7.33 6.18532 7.51267 5.99999 7.73333 5.99999H12M0 0.666656H12M0 3.33332H12" stroke="#0071BB"/>
                        </svg>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Portfolio</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link :to="{name: ''}" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 12" fill="none">
                          <path d="M16.5 0.0625H1.5C1.25136 0.0625 1.0129 0.161272 0.837087 0.337087C0.661272 0.512903 0.5625 0.75136 0.5625 1V11C0.5625 11.2486 0.661272 11.4871 0.837087 11.6629C1.0129 11.8387 1.25136 11.9375 1.5 11.9375H16.5C16.7486 11.9375 16.9871 11.8387 17.1629 11.6629C17.3387 11.4871 17.4375 11.2486 17.4375 11V1C17.4375 0.75136 17.3387 0.512903 17.1629 0.337087C16.9871 0.161272 16.7486 0.0625 16.5 0.0625ZM1.5 0.6875H16.5C16.5829 0.6875 16.6624 0.720424 16.721 0.779029C16.7796 0.837634 16.8125 0.91712 16.8125 1V3.25781H1.1875V1C1.1875 0.91712 1.22042 0.837634 1.27903 0.779029C1.33763 0.720424 1.41712 0.6875 1.5 0.6875ZM16.5 11.3125H1.5C1.41712 11.3125 1.33763 11.2796 1.27903 11.221C1.22042 11.1624 1.1875 11.0829 1.1875 11V3.88281H16.8125V11C16.8125 11.0829 16.7796 11.1624 16.721 11.221C16.6624 11.2796 16.5829 11.3125 16.5 11.3125ZM14.9375 9.125C14.9375 9.20788 14.9046 9.28737 14.846 9.34597C14.7874 9.40458 14.7079 9.4375 14.625 9.4375H12.125C12.0421 9.4375 11.9626 9.40458 11.904 9.34597C11.8454 9.28737 11.8125 9.20788 11.8125 9.125C11.8125 9.04212 11.8454 8.96263 11.904 8.90403C11.9626 8.84542 12.0421 8.8125 12.125 8.8125H14.625C14.7079 8.8125 14.7874 8.84542 14.846 8.90403C14.9046 8.96263 14.9375 9.04212 14.9375 9.125ZM9.9375 9.125C9.9375 9.20788 9.90458 9.28737 9.84597 9.34597C9.78737 9.40458 9.70788 9.4375 9.625 9.4375H8.375C8.29212 9.4375 8.21263 9.40458 8.15403 9.34597C8.09542 9.28737 8.0625 9.20788 8.0625 9.125C8.0625 9.04212 8.09542 8.96263 8.15403 8.90403C8.21263 8.84542 8.29212 8.8125 8.375 8.8125H9.625C9.70788 8.8125 9.78737 8.84542 9.84597 8.90403C9.90458 8.96263 9.9375 9.04212 9.9375 9.125Z" fill="#0071BB"/>
                          </svg>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">Funds</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link :to="{name: 'dashboard.reports'}" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 16" fill="none">
                        <path d="M11 11V5M8 11V7.25M5 11V8.75" stroke="#0071BB" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M1.25 14.3V1.7C1.25 1.58065 1.29741 1.46619 1.3818 1.3818C1.46619 1.29741 1.58065 1.25 1.7 1.25H14.3C14.4193 1.25 14.5338 1.29741 14.6182 1.3818C14.7026 1.46619 14.75 1.58065 14.75 1.7V14.3C14.75 14.4193 14.7026 14.5338 14.6182 14.6182C14.5338 14.7026 14.4193 14.75 14.3 14.75H1.7C1.58065 14.75 1.46619 14.7026 1.3818 14.6182C1.29741 14.5338 1.25 14.4193 1.25 14.3V14.3Z" stroke="#0071BB"/>
                          </svg>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Reports</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link :to="{name: ''}" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 17" fill="none">
                        <path d="M15.6668 7.16667C15.6668 5.39856 14.9645 3.70286 13.7142 2.45262C12.464 1.20238 10.7683 0.5 9.00016 0.5C7.23205 0.5 5.53636 1.20238 4.28612 2.45262C3.03588 3.70286 2.3335 5.39856 2.3335 7.16667" stroke="#0071BB" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.6667 13V13.4167C15.6667 13.8587 15.4912 14.2826 15.1786 14.5952C14.866 14.9077 14.4421 15.0833 14.0001 15.0833H11.0834M0.666748 10.865V9.30167C0.666804 8.93002 0.791084 8.56904 1.01984 8.27612C1.24859 7.98319 1.56868 7.77514 1.92925 7.68501L3.37925 7.32167C3.45293 7.30332 3.52982 7.302 3.60409 7.31779C3.67836 7.33359 3.74806 7.36609 3.80789 7.41283C3.86773 7.45958 3.91613 7.51934 3.94943 7.58758C3.98273 7.65582 4.00005 7.73074 4.00008 7.80667V12.3592C4.00021 12.4353 3.98298 12.5104 3.94968 12.5788C3.91639 12.6472 3.86792 12.7071 3.80797 12.754C3.74801 12.8009 3.67815 12.8334 3.60371 12.8492C3.52928 12.865 3.45222 12.8635 3.37841 12.845L1.92841 12.4825C1.568 12.3922 1.2481 12.1841 1.01951 11.8912C0.790918 11.5983 0.666758 11.2374 0.666748 10.8658V10.865ZM17.3334 10.865V9.30167C17.3334 8.93002 17.2091 8.56904 16.9803 8.27612C16.7516 7.98319 16.4315 7.77514 16.0709 7.68501L14.6209 7.32167C14.5472 7.30332 14.4703 7.302 14.3961 7.31779C14.3218 7.33359 14.2521 7.36609 14.1923 7.41283C14.1324 7.45958 14.084 7.51934 14.0507 7.58758C14.0174 7.65582 14.0001 7.73074 14.0001 7.80667V12.3592C14 12.4352 14.0172 12.5102 14.0505 12.5785C14.0837 12.6469 14.1321 12.7068 14.1919 12.7536C14.2518 12.8004 14.3215 12.833 14.3959 12.8488C14.4702 12.8647 14.5472 12.8634 14.6209 12.845L16.0709 12.4825C16.4315 12.3924 16.7516 12.1843 16.9803 11.8914C17.2091 11.5985 17.3334 11.2375 17.3334 10.8658V10.865Z" stroke="#0071BB"/>
                        <path d="M10.25 16.3333H7.75C7.41848 16.3333 7.10054 16.2016 6.86612 15.9672C6.6317 15.7328 6.5 15.4149 6.5 15.0833C6.5 14.7518 6.6317 14.4339 6.86612 14.1995C7.10054 13.965 7.41848 13.8333 7.75 13.8333H10.25C10.5815 13.8333 10.8995 13.965 11.1339 14.1995C11.3683 14.4339 11.5 14.7518 11.5 15.0833C11.5 15.4149 11.3683 15.7328 11.1339 15.9672C10.8995 16.2016 10.5815 16.3333 10.25 16.3333Z" stroke="#0071BB"/>
                        </svg>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Contact us</h6>
                      </div>
                    </div>
                  </router-link>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a class="btn btn-primary dark-btn-primary" href="javascript:void(0)" @click="logout" role="button">{{ $t('nav.user.signout') }}<i class="ri-login-box-line ml-2"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
    </nav>
    <nav class="navbar navbar-expand-sm navbar-light bg-primary full-nav">
      <ul class="navbar-nav me-auto navbar-list navbar-margin nav-grid">
        <li class="nav-item ">
          <router-link to="/dashboard/index" class="nav-link text-light"><span class="ml-2 header"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M6.25 13.5833H11.75M13.5833 17.25H4.41667C3.44421 17.25 2.51158 16.8637 1.82394 16.1761C1.13631 15.4884 0.75 14.5558 0.75 13.5833V7.81567C0.749987 7.1852 0.91254 6.56538 1.22196 6.01607C1.53138 5.46675 1.97722 5.0065 2.51642 4.67976L7.09975 1.90226C7.67277 1.55502 8.32998 1.37143 9 1.37143C9.67002 1.37143 10.3272 1.55502 10.9003 1.90226L15.4836 4.67976C16.0226 5.00642 16.4684 5.46652 16.7778 6.01567C17.0872 6.56481 17.2499 7.18444 17.25 7.81476V13.5833C17.25 14.5558 16.8637 15.4884 16.1761 16.1761C15.4884 16.8637 14.5558 17.25 13.5833 17.25Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg></span> Dashboard</router-link>
        </li>
        <li class="nav-item"><router-link to="/dashboard/orders" class="nav-link text-light"><span class="ml-2 order"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 14" fill="none">
          <path d="M2 0.25C1.1675 0.25 0.5 0.9175 0.5 1.75V4.75C0.5 5.5825 1.1675 6.25 2 6.25H5C5.8325 6.25 6.5 5.5825 6.5 4.75V1.75C6.5 0.9175 5.8325 0.25 5 0.25M5.15 1.375L5.945 2.1625L2.9525 5.125L1.055 3.2125L1.8575 2.425L2.96 3.5425M2 7.75C1.1675 7.75 0.5 8.4175 0.5 9.25V12.25C0.5 13.0825 1.1675 13.75 2 13.75H5C5.8325 13.75 6.5 13.0825 6.5 12.25V9.25C6.5 8.4175 5.8325 7.75 5 7.75M2 9.25H5V12.25H2M8 1.75H15.5V3.25H8M8 12.25V10.75H15.5V12.25M8 6.25H15.5V7.75H8V6.25Z" fill="white"/>
          </svg></span> Orders</router-link>
        </li>
        <li class="nav-item dropdown"><router-link to="/dashboard/portfolio" class="nav-link dropdown-toggle text-light"><span class="ml-2 header"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" viewBox="0 0 12 8" fill="none">
          <path d="M0 5.99999H4.26667C4.48733 5.99999 4.67 6.18532 4.76667 6.38399C4.904 6.66599 5.22933 6.99999 6 6.99999C6.77067 6.99999 7.096 6.66666 7.23333 6.38399C7.33 6.18532 7.51267 5.99999 7.73333 5.99999H12M0 0.666656H12M0 3.33332H12" stroke="white"/>
          </svg></span> Portfolio</router-link>
          <!-- <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="#">All Security Types</a></li>
            <li><a class="dropdown-item" href="#">Equity</a></li>
            <li><a class="dropdown-item" href="#">CFD</a></li>
            <li><a class="dropdown-item" href="#">Futures</a></li>
            <li><a class="dropdown-item" href="#">Options</a></li>
            <li><a class="dropdown-item" href="#">Bonds</a></li>
            <li><a class="dropdown-item" href="#">Structured notes</a></li>
          </ul> -->
        </li>
        <li class="nav-item"><router-link to="/dashboard/funds" class="nav-link text-light"><span class="ml-2 header"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 12" fill="none">
          <path d="M16.5 0.0625H1.5C1.25136 0.0625 1.0129 0.161272 0.837087 0.337087C0.661272 0.512903 0.5625 0.75136 0.5625 1V11C0.5625 11.2486 0.661272 11.4871 0.837087 11.6629C1.0129 11.8387 1.25136 11.9375 1.5 11.9375H16.5C16.7486 11.9375 16.9871 11.8387 17.1629 11.6629C17.3387 11.4871 17.4375 11.2486 17.4375 11V1C17.4375 0.75136 17.3387 0.512903 17.1629 0.337087C16.9871 0.161272 16.7486 0.0625 16.5 0.0625ZM1.5 0.6875H16.5C16.5829 0.6875 16.6624 0.720424 16.721 0.779029C16.7796 0.837634 16.8125 0.91712 16.8125 1V3.25781H1.1875V1C1.1875 0.91712 1.22042 0.837634 1.27903 0.779029C1.33763 0.720424 1.41712 0.6875 1.5 0.6875ZM16.5 11.3125H1.5C1.41712 11.3125 1.33763 11.2796 1.27903 11.221C1.22042 11.1624 1.1875 11.0829 1.1875 11V3.88281H16.8125V11C16.8125 11.0829 16.7796 11.1624 16.721 11.221C16.6624 11.2796 16.5829 11.3125 16.5 11.3125ZM14.9375 9.125C14.9375 9.20788 14.9046 9.28737 14.846 9.34597C14.7874 9.40458 14.7079 9.4375 14.625 9.4375H12.125C12.0421 9.4375 11.9626 9.40458 11.904 9.34597C11.8454 9.28737 11.8125 9.20788 11.8125 9.125C11.8125 9.04212 11.8454 8.96263 11.904 8.90403C11.9626 8.84542 12.0421 8.8125 12.125 8.8125H14.625C14.7079 8.8125 14.7874 8.84542 14.846 8.90403C14.9046 8.96263 14.9375 9.04212 14.9375 9.125ZM9.9375 9.125C9.9375 9.20788 9.90458 9.28737 9.84597 9.34597C9.78737 9.40458 9.70788 9.4375 9.625 9.4375H8.375C8.29212 9.4375 8.21263 9.40458 8.15403 9.34597C8.09542 9.28737 8.0625 9.20788 8.0625 9.125C8.0625 9.04212 8.09542 8.96263 8.15403 8.90403C8.21263 8.84542 8.29212 8.8125 8.375 8.8125H9.625C9.70788 8.8125 9.78737 8.84542 9.84597 8.90403C9.90458 8.96263 9.9375 9.04212 9.9375 9.125Z" fill="white"/>
          </svg></span> Funds</router-link>
        </li>
        <li class="nav-item dropdown">
          <router-link to="/dashboard/reports" class="nav-link text-light dropdown-toggle">
            <span class="ml-2 header"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 16" fill="none">
          <path d="M11 11V5M8 11V7.25M5 11V8.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.25 14.3V1.7C1.25 1.58065 1.29741 1.46619 1.3818 1.3818C1.46619 1.29741 1.58065 1.25 1.7 1.25H14.3C14.4193 1.25 14.5338 1.29741 14.6182 1.3818C14.7026 1.46619 14.75 1.58065 14.75 1.7V14.3C14.75 14.4193 14.7026 14.5338 14.6182 14.6182C14.5338 14.7026 14.4193 14.75 14.3 14.75H1.7C1.58065 14.75 1.46619 14.7026 1.3818 14.6182C1.29741 14.5338 1.25 14.4193 1.25 14.3V14.3Z" stroke="white"/>
            </svg></span> Reports
          </router-link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="#">Trading History</a></li>
            <li><a class="dropdown-item" href="#">P&L</a></li>
            <li><a class="dropdown-item" href="#">Balance Summary</a></li>
          </ul>
        </li>
        <li class="nav-item"><router-link to="/dashboard/contact" class="nav-link text-light"><span class="ml-2 header"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 17" fill="none">
          <path d="M15.6668 7.16667C15.6668 5.39856 14.9645 3.70286 13.7142 2.45262C12.464 1.20238 10.7683 0.5 9.00016 0.5C7.23205 0.5 5.53636 1.20238 4.28612 2.45262C3.03588 3.70286 2.3335 5.39856 2.3335 7.16667" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.6667 13V13.4167C15.6667 13.8587 15.4912 14.2826 15.1786 14.5952C14.866 14.9077 14.4421 15.0833 14.0001 15.0833H11.0834M0.666748 10.865V9.30167C0.666804 8.93002 0.791084 8.56904 1.01984 8.27612C1.24859 7.98319 1.56868 7.77514 1.92925 7.68501L3.37925 7.32167C3.45293 7.30332 3.52982 7.302 3.60409 7.31779C3.67836 7.33359 3.74806 7.36609 3.80789 7.41283C3.86773 7.45958 3.91613 7.51934 3.94943 7.58758C3.98273 7.65582 4.00005 7.73074 4.00008 7.80667V12.3592C4.00021 12.4353 3.98298 12.5104 3.94968 12.5788C3.91639 12.6472 3.86792 12.7071 3.80797 12.754C3.74801 12.8009 3.67815 12.8334 3.60371 12.8492C3.52928 12.865 3.45222 12.8635 3.37841 12.845L1.92841 12.4825C1.568 12.3922 1.2481 12.1841 1.01951 11.8912C0.790918 11.5983 0.666758 11.2374 0.666748 10.8658V10.865ZM17.3334 10.865V9.30167C17.3334 8.93002 17.2091 8.56904 16.9803 8.27612C16.7516 7.98319 16.4315 7.77514 16.0709 7.68501L14.6209 7.32167C14.5472 7.30332 14.4703 7.302 14.3961 7.31779C14.3218 7.33359 14.2521 7.36609 14.1923 7.41283C14.1324 7.45958 14.084 7.51934 14.0507 7.58758C14.0174 7.65582 14.0001 7.73074 14.0001 7.80667V12.3592C14 12.4352 14.0172 12.5102 14.0505 12.5785C14.0837 12.6469 14.1321 12.7068 14.1919 12.7536C14.2518 12.8004 14.3215 12.833 14.3959 12.8488C14.4702 12.8647 14.5472 12.8634 14.6209 12.845L16.0709 12.4825C16.4315 12.3924 16.7516 12.1843 16.9803 11.8914C17.2091 11.5985 17.3334 11.2375 17.3334 10.8658V10.865Z" stroke="white"/>
          <path d="M10.25 16.3333H7.75C7.41848 16.3333 7.10054 16.2016 6.86612 15.9672C6.6317 15.7328 6.5 15.4149 6.5 15.0833C6.5 14.7518 6.6317 14.4339 6.86612 14.1995C7.10054 13.965 7.41848 13.8333 7.75 13.8333H10.25C10.5815 13.8333 10.8995 13.965 11.1339 14.1995C11.3683 14.4339 11.5 14.7518 11.5 15.0833C11.5 15.4149 11.3683 15.7328 11.1339 15.9672C10.8995 16.2016 10.5815 16.3333 10.25 16.3333Z" stroke="white"/>
          </svg></span> Contact us</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { core, APPNAME } from '../../../../config/pluginInit'
import langChange from '../../../../Mixins/langchange'
export default {
  name: 'HeaderStyle',
  mixins: [langChange],
  components: {
  },
  data () {
    return {
      appName: APPNAME
    }
  },
  mounted () {
    if (!this.horizontal) {
      document.addEventListener('click', this.closeSearch, true)
    }
  },
  methods: {
    sidebarMini () {
      core.triggerSet()
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = 'iq-show'
      // this.globalSearch = ''
      if (document.getElementById('searchbox-datalink') !== null) {
        document.getElementById('searchbox-datalink').classList.add('show-data')
      }
    },
    closeSearch (event) {
      const classList = event.target.classList
      if (!classList.contains('searchbox') && !classList.contains('search-input')) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ''
      if (document.getElementById('searchbox-datalink') !== null && document.getElementById('searchbox-datalink') !== undefined) {
        document.getElementById('searchbox-datalink').classList.remove('show-data')
      }
    },
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('loginData')
      this.$router.push({ path: '/auth/sign-in1' })
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction'
    })

  },

  computed: {
    ...mapGetters({
      bookmark: 'Setting/bookmarkState',
      langsOptions: 'Setting/langOptionState',
      selectedLang: 'Setting/langState'
    })
  }
}
</script>
<style>
.logout{
  font-weight: bold;
  cursor: pointer;
}
</style>
