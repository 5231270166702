<template>
  <div>
    <Loader />
    <div class="wrapper">
       <HeaderStyle />
       <div class="content-page" id="content-page">
           <!-- <transition name="router-anim" enter-active-class="animated  fadeInUp" mode="out-in" -->
                    <!-- leave-active-class="animated fadeOut" > -->
              <router-view />
            <!-- </transition> -->
        </div>
     </div>
  </div>
</template>
<script>
import HeaderStyle from '../components/core/partials/HeaderStyle/HeaderStyle.vue'
import Loader from '../components/core/loader/Loader'
export default {
  name: 'Layout1',
  components: {
    Loader,
    HeaderStyle
  },
  methods: {
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
